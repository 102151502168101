<template>
    <div>
        <loader v-if="isConfirmationLoading" />
        <st-modal
            ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="$t('INTERNAL_DOCUMENTS.FORM.SIGN_DOCUMENT_TITLE')"
            :hideHeaderClose="isLoading"
            customClass="form-modal"
            :id="modalId"
            @modalHidden="closeModal"
        >
            <template #body>
                <form class="form" novalidate="novalidate" id="st_modal_confirm_sign_form">
                    <div class="row pb-6">
                        <div class="col">
                            <div class="form-group">
                            <label>
                                    {{ fields.pin.label }}
                                </label>
                                <st-input-text
                                    :ref="fields.pin.name"
                                    :name="fields.pin.name"
                                    v-model="model[fields.pin.name]"
                                    set-validation
                                    :max-length="6"
                                    @input="resetConfirmationStatus"
                                />
                            </div>
                        </div>
                        <div class="col-12">
                            <span v-if="confirmationStatus === 'invalid_pin'" class="text-danger">{{ $t('INTERNAL_DOCUMENTS.SIGN_CONFIRMATION.INVALID_PIN') }}</span>
                            <span v-if="confirmationStatus === 'error'" class="text-danger">{{ $t('INTERNAL_DOCUMENTS.SIGN_CONFIRMATION.ERROR') }}</span>
                            <span v-if="confirmationStatus === 'pin_expired'" class="text-danger">{{ $t('INTERNAL_DOCUMENTS.SIGN_CONFIRMATION.PIN_EXPIRED') }}</span>
                            <span v-if="confirmationStatus === 'session_expired'" class="text-danger">{{ $t('INTERNAL_DOCUMENTS.SIGN_CONFIRMATION.SESSION_EXPIRED') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="hide"
                        > {{ cancelText }}
                        </st-button>

                        <st-button
                            size="large"
                            variant="primary"
                            :callback="signDocument"
                            :spinner="isConfirmationLoading"
                            :disabled="isConfirmationLoading"
                        >
                            <span>{{ submitText }}</span>
                        </st-button>
                    </div>
                </form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { InternalDocumentsModel } from "@/modules/internal-documents/models/internal-documents-model";
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { generateRandomId } from '@/core/helpers/globalMethods';

const { fields } =  InternalDocumentsModel;

const formSchema = new FormSchema([
   fields.pin,
]);

export default {
    name: 'ConfirmSignDocumentModal',
    props: {
        id: {
            type: [String, Array],
            required: true,
        },
        bulkSigning: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
            modalId: `st-modal-confirm-document-sign-${generateRandomId()}`,
            polling: null,
            confirmationStatus: '',
            isConfirmationLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser'
        }),
        isLoading() {
            return this.loading['internalDocuments/confirm-sign'];
        },
        cancelText() {
            return this.$t('GENERAL.BUTTON.CANCEL');
        },
        submitText() {
            return this.$t('GENERAL.ACTIONS.SIGN');
        },
    },
    methods: {
        ...mapActions({
            doValidateDocumentSign: 'internalDocuments/form/validateDocumentSign',
            checkSigningStatus: 'internalDocuments/form/checkSigningStatus',
            doValidateBulkDocumentSign: 'internalDocuments/form/validateBulkDocumentSign',
            checkBulkSigningStatus: 'internalDocuments/form/checkStaffBulkSigningStatus',
        }),
        show(data) {
            if (this.bulkSigning && data.context.failed_identification_numbers) {
                this.confirmationStatus = 'bulk_failed';
                this.bulkFailedApplications = data.context.failed_identification_numbers;
            }

            this.$refs['modal'].show();
        },
        hide(isSigned) {
            if (isSigned) {
                this.$emit('signed');
            }

            this.confirmationStatus = '';
            this.model.pin = '';
            this.clearPollingScraps();

            this.$refs['modal'].hide();
        },
        closeModal() {
            this.hide();
        },
        async signDocument() {
            if (this.bulkSigning) {
                await this.doBulkSign(true);
            } else {
                await this.doSubmit();
            }
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    id: this.id,
                    pin: this.model.pin,
                }

                try {
                    this.isConfirmationLoading = true;
                    const response = await this.doValidateDocumentSign(payload);

                    switch(response) {
                        case 'error':
                            this.confirmationStatus = response;
                            this.isConfirmationLoading = false;
                            break;
                        case 'pending':
                            this.polling = setInterval(() => {
                                this.checkSignConfirmation(this.id);
                            }, 5000); // TO DO: Decrease interval to 1000
                            break;
                    }
                } catch(error) {
                    this.isConfirmationLoading = false;
                }
            }
        },
        async checkSignConfirmation(id) {
            try {
                const status = await this.checkSigningStatus(id);
                if (status !== 'pending') {
                    this.confirmationStatus = status;
                    this.isConfirmationLoading = false;
                    clearInterval(this.polling);
                }

                if (status === 'successful') {
                    this.hide(true);
                }
            } catch(error) {
                this.confirmationStatus = 'error';
                this.isConfirmationLoading = false;
                clearInterval(this.polling);
            }
        },
        resetConfirmationStatus() {
            if (this.confirmationStatus) {
                this.confirmationStatus = '';
            }
        },
        clearPollingScraps() {
            if (this.polling) {
                clearInterval(this.polling);
            }
        },
        async doBulkSign(shouldWait) {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const ids = this.id.map((el) => el.id);

                const payload = {
                    ids,
                    pin: this.model.pin,
                }

                if (!shouldWait) {
                    this.doValidateBulkDocumentSign(payload);
                    return this.hide(true);
                }

                try {
                    this.isConfirmationLoading = true;
                    const response = await this.doValidateBulkDocumentSign(payload);
                    const formattedResponse = response.map((el) => el.signing_status_staff)
                        .filter((el, index, self) => self.indexOf(el) == index);

                    if (formattedResponse.length === 1) {
                        switch(formattedResponse[0]) {
                            case 'error':
                            case 'invalid_pin':
                            case 'pin_expired':
                            case 'session_expired':
                                this.confirmationStatus = formattedResponse[0];
                                this.isConfirmationLoading = false;
                                break;
                            case 'pending':
                                this.polling = setInterval(() => {
                                    this.checkBulkSignConfirmation(ids);
                                }, 5000); // TO DO: Decrease interval to 1000
                                break;
                            case 'successful':
                                this.isConfirmationLoading = false;
                                this.hide(true);
                                break;
                        }
                    } else {
                        //check if at least one is pending
                        const isPending = formattedResponse.some((el) => el === 'pending');
                        if (isPending) {
                            this.polling = setInterval(() => {
                                this.checkBulkSignConfirmation(ids);
                            }, 5000); // TO DO: Decrease interval to 1000
                        }
                    }
                } catch(error) {
                    this.isConfirmationLoading = false;
                }
            }
        },
        async checkBulkSignConfirmation(ids) {
            try {
                const status = await this.checkBulkSigningStatus(ids);
                const formattedResponse = status.map((el) => el.signing_status_staff)
                        .filter((el, index, self) => self.indexOf(el) == index);

                if (formattedResponse.length === 1) {
                    if (formattedResponse[0] !== 'pending') {
                        this.confirmationStatus = formattedResponse[0];
                        this.isConfirmationLoading = false;
                        clearInterval(this.polling);
                    }

                    if (formattedResponse[0] === 'successful') {
                        this.hide(true);
                    }
                } else {
                    //check if at least one is successful
                    const isSuccessful = formattedResponse.some((el) => el === 'successful');
                    if (isSuccessful) {
                        clearInterval(this.polling);
                        this.isConfirmationLoading = false;
                        this.hide(true);
                    }
                }
            } catch(error) {
                this.confirmationStatus = 'error';
                this.isConfirmationLoading = false;
                clearInterval(this.polling);
            }
        }
    },
    async created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                this.fv = createFormValidation(this.modalId, this.rules);
            }
        });
    },
    destroy() {
        if (this.polling){
            clearInterval(this.polling);
        }
    }
}
</script>
