import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import ComputedField from '@/shared/fields/computed-field';
import { InternalDocumentTypeField } from '@/modules/internal-documents/fields/internal-document-type-field';
import BooleanField from '@/shared/fields/boolean-field';

const label = (name) => i18n.t(`INTERNAL_DOCUMENTS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`INTERNAL_DOCUMENTS.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`INTERNAL_DOCUMENTS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const STATUSES = [
    {
        value: 'draft',
        name: 'draft',
        text: enumLabel('status', 'draft'),
        type: 'dark',
        sortkey: 0
    },
    {
        value: 'new',
        name: 'new',
        text: enumLabel('status', 'new'),
        type: 'primary',
        sortkey: 1
    },
    {
        value: 'pending',
        name: 'pending',
        text: enumLabel('status', 'pending'),
        type: 'warning',
        sortkey: 2
    },
    {
        value: 'rejected',
        name: 'rejected',
        text: enumLabel('status', 'rejected'),
        type: 'danger',
        sortkey: 6,
    },
    {
        value: 'review',
        name: 'review',
        text: enumLabel('status', 'review'),
        type: 'warning',
        sortkey: 3
    },
    {
        value: 'approved',
        name: 'approved',
        text: enumLabel('status', 'approved'),
        type: 'warning',
        internal: true,
        sortkey: 4
    },
    {
        value: 'closed',
        name: 'closed',
        text: enumLabel('status', 'closed'),
        type: 'success',
        sortkey: 7
    },
    {
        value: 'sign_pending',
        name: 'sign_pending',
        text: enumLabel('status', 'sign_pending'),
        type: 'warning',
        internal: true,
        sortkey: 5
    },
    {
        value: 'rejected_in_progress',
        name: 'rejected_in_progress',
        text: enumLabel('status', 'rejected_in_progress'),
        internal: true,
        type: 'warning',
        sortkey: 5,
    },
    {
        value: 'signed',
        name: 'signed',
        text: enumLabel('status', 'signed'),
        type: 'warning',
        internal: true,
        sortkey: 7,
        display: false
    },
];

const fields = {
    id: new IdField('id', 'Id'),
    identification_number: new StringField('identification_number', label('identification_number'), {},
        {
            placeholder: placeholder('identification_number'),
            primary: true,
        }),
    application_type_name: new StringField('application_type_name', label('application_type_name_column'),{},
        {
            placeholder: placeholder('application_type_name'),
            primary: false,
        }),
    application_type_id: InternalDocumentTypeField.relationToOne(
        'application_type_id',
        label('application_type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('application_type_name')},
        [],
        { asyncData: true, hasPagination: true }
    ),
    status: new EnumField(
        'status',
        label('status'),
        [],
        {},
        { placeholder: placeholder('status')}
    ),
    internal_status: new EnumField(
        'internal_status',
        label('status'),
        [],
        {},
        { placeholder: placeholder('status')}
    ),
    created_date: new DateField('created_date', label('created_date'),{},{primary: false,}),
    application_date: new DateField('application_date', label('application_date')),
    response_due_in_days: new ComputedField('response_due_in_days', label('response_due_in'),
    (record) => {
        if (!record || record.response_due_in_days === undefined) return '';
        return `${record.response_due_in_days} ${i18n.tc('GENERAL.DAYS', Number(record.response_due_in_days))}`
    }),
    pin: new StringField('pin', label('pin'), { required: true, min: 6 }),
    assigned_staff_user_name: new ComputedField('assigned_staff_user_name', label('assigned_to'),
        ({assigned_staff_user_name}) => {
            if (!assigned_staff_user_name) return i18n.t('APPLICATION.LIST.NO_ASSIGNEE');
            return assigned_staff_user_name;
        },
        {},
        {
            placeholder: placeholder('assigned_to'),
            primary: false,
        }
    ),
    created_by: new ComputedField('created_by', label('created_by'),
        (record) => {
            if (!record.applicant_user?.first_name)  return '';
            return `${record.applicant_user.first_name} ${record.applicant_user.last_name} `;
        }
    ),
    is_signed: new BooleanField('is_signed',label('is_signed')),
    allocated_document_series: new StringField('allocated_document_series', label('allocated_document_series'), {},
    {
        placeholder: placeholder('allocated_document_series'),
        primary: false,
    }),
    comments: new StringField('comments', label('comments'), { required: true, }, { placeholder: placeholder('comments') }),
    comment: new StringField('comment', label('review_comment'), { required: true }),
    parent_application_identification_number: new StringField('parent_application_identification_number', label('parent_application_identification_number'),
    {}, {
        placeholder: placeholder('parent_application_identification_number'),
        primary: true,
    }),
    selected: new BooleanField('selected'),
};

export class InternalDocumentsModel extends GenericModel {
    static get fields() {
        return fields;
    }
    static get statuses() {
        return STATUSES;
    }
}
